@import 'src/styles/variables.scss';

.dropzone-container {
  width: 88px;
  height: 88px;
  background-color: $background-color;
  margin: 0 16px 16px 0;
  position: relative;

  &.is-active {
    border: 1px solid black;
  }

  .dropzone-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .preview-image {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .preview-bin {
    position: absolute;
    bottom: -3px;
    right: 3px;
    cursor: pointer;
  }
}
