@import 'src/styles/variables.scss';

.photos-guidelines {
  .example-subtitle {
    font-family: $font-SourceSansPro-semibold;
    font-size: 18px;
    line-height: 24px;
    margin: 16px 0 8px;
  }

  .examples-container {
    margin-bottom: 24px;
  }

  .example-photos {
    max-height: 95px;
    max-width: 311px;
    width: 100%;
  }
}
