@import 'src/styles/variables.scss';

.plan-content {
  .image-upload-precision {
    margin-bottom: 32px;
  }

  .image-upload-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  .examples-button {
    color: #7a41ff;
  }

  .media-not-accepted {
    &__content {
      background: $red-bright;
      padding: 20px;
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__text {
      margin-top: 10px;
    }

    &__divider {
      margin: 30px 0;
      height: 1px;
      background: $background-color;
    }
  }
}
